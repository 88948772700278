import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import SectionItem from '../components/SectionItem';
import SubTitlePage from '../components/SubTitlePage';
import Banner from '../components/Banner';
import Container from '../components/Container';
import RichText from '../components/RichText';
import useResponsiveImage from '../components/useResponsiveImage';


import * as S from './landing-page.styled';

const LandingPage = props => {
  const locale = props.data.markdownRemark.fields.locale;
  const pageContent = props.data.markdownRemark.frontmatter;
  const bannerContent = {
    "image": pageContent.bannerImage,
    "text": pageContent.title,
    "bullet1": pageContent.bullet1,
    "bullet2": pageContent.bullet2,
    "bullet3": pageContent.bullet3, 
    "buttonText": pageContent.buttonText,
    "buttonUrlInternal": pageContent.buttonUrlInternal       
  }

  return (
    <>

      <SEO 
        title={pageContent.title} 
        description={`✓ ${pageContent.bullet1} ✓ ${pageContent.bullet2} ✓ ${pageContent.bullet3}`} 
        lang={locale} />
      <Banner bannerContent={bannerContent} lang={locale} />

      <S.SectionAlt>
        <Container>

          <S.LogosText dangerouslySetInnerHTML={{ __html: pageContent.logos.text}}></S.LogosText>

          <S.Logos>
            {pageContent.logos.items.slice(0, 5).map(
              ({
                alt,
                image,
              }, index) => (
                <S.Logo alt={alt} src={image} key={index} width="150px" height="73px"/>

              ),
            )}                
          </S.Logos>

        </Container> 
      </S.SectionAlt>      

      <S.SectionGradient>
        <Container>
          <S.Row>
            <S.Column>
              <S.Tag>{pageContent.section1.tag} </S.Tag>
              <SubTitlePage text={pageContent.section1.title} />
              <RichText dangerouslySetInnerHTML={{ __html: pageContent.section1.description }}/>                 
            </S.Column>
            <S.ColumnWide>
              <S.IntroImage
                  fluid={useResponsiveImage(pageContent.section1.image, false)}
                  alt={pageContent.section3.title}
                  fadeIn={false}
                />                
            </S.ColumnWide>

          </S.Row>
          {
            pageContent.section1.items.length > 2 ? 
              <S.Row>
              {pageContent.section1.items.slice(0, 3).map(
                ({
                  title,
                  text,
                  image,
                }, index) => (
                  <S.Column key={index}>
                    <SectionItem
                    title={title}
                    text={text}
                    image={image}
                  />                
                  </S.Column>

                ),
              )}
              </S.Row>           
            : null
          }
        </Container>
        </S.SectionGradient> 
        <S.Section>
        <Container>
            <S.RowReverse>
            <S.Column>
                <S.Tag>{pageContent.section2.tag} </S.Tag>
                <SubTitlePage text={pageContent.section2.title} />
                <RichText dangerouslySetInnerHTML={{ __html: pageContent.section2.description }}/>                 
              </S.Column>              
            <S.ColumnWide>
                <S.IntroImage
                    fluid={useResponsiveImage(pageContent.section2.image, false)}
                    alt={pageContent.section3.title}
                    fadeIn={false}
                  />                
              </S.ColumnWide>              
          </S.RowReverse>
          {
            pageContent.section2.items.length > 2 ? 
              <S.Row>
              {pageContent.section2.items.slice(0, 3).map(
                ({
                  title,
                  text,
                  image,
                }, index) => (
                  <S.Column key={index}>
                    <SectionItem
                    title={title}
                    text={text}
                    image={image}
                  />                
                  </S.Column>

                ),
              )}
              </S.Row>           
            : null
          }
        </Container>        
      </S.Section>
      
      <S.SectionAlt>
        <Container>

          <S.Row>
            <S.Column>
              <S.Tag>{pageContent.section3.tag} </S.Tag>
              <SubTitlePage text={pageContent.section3.title} />
              <RichText dangerouslySetInnerHTML={{ __html: pageContent.section3.description }}/>                 
            </S.Column>
            <S.ColumnWide>
                <S.IntroImage
                fluid={useResponsiveImage(pageContent.section3.image, false)}
                alt={pageContent.section3.title}
                fadeIn={false}
              />           
            </S.ColumnWide>
          </S.Row>

          {
            pageContent.section3.items.length > 2 ? 
              <S.Row>
              {pageContent.section3.items.slice(0, 3).map(
                ({
                  title,
                  text,
                  image,
                }, index) => (
                  <S.Column key={index}>
                    <SectionItem
                    title={title}
                    text={text}
                    image={image}
                  />                
                  </S.Column>

                ),
              )}
              </S.Row>           
            : null
          }
        </Container>
      </S.SectionAlt>


      <S.Section>
        <Container>

          <S.RowReverse>
            <S.ColumnWide>
              <S.Tag>{pageContent.feature1.tag} </S.Tag>
              <SubTitlePage text={pageContent.feature1.title} />
              <RichText dangerouslySetInnerHTML={{ __html: pageContent.feature1.description }}/>                 
            </S.ColumnWide>
            <S.Column>
                <S.IntroImage
                fluid={useResponsiveImage(pageContent.feature1.image, false)}
                alt={pageContent.feature1.title}
                fadeIn={false}
              />           
            </S.Column>
          </S.RowReverse>
   

        </Container>
      </S.Section>

      <S.SectionAlt>
        <Container>

          <S.Row>
            <S.ColumnWide>
              <S.Tag>{pageContent.feature3.tag} </S.Tag>
              <SubTitlePage text={pageContent.feature3.title} />
              <RichText dangerouslySetInnerHTML={{ __html: pageContent.feature3.description }}/>                 
            </S.ColumnWide>
            <S.Column>
                <S.IntroImage
                fluid={useResponsiveImage(pageContent.feature3.image, false)}
                alt={pageContent.feature3.title}
                fadeIn={false}
              />           
            </S.Column>
          </S.Row>
   

        </Container>
      </S.SectionAlt>


    </>
  );
};

export default LandingPage;

export const query = graphql`
  query LandingPage($locale: String!, $title: String!) { 
    markdownRemark(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      fields {
        locale
      }
      frontmatter {
        templateKey
        navigationTitle
        ctaTitle
        title
        bullet1
        bullet2
        bullet3
        buttonText
        buttonUrlInternal
        description
        bannerImage
        logos{
          text
          items{
            alt
            image
          }
        }
        section1 {
          tag
          title
          description
          image
          items {
            title
            text
            image 
          }         
        } 
        section2 {
          tag
          title
          description
          image
          items {
            title
            text
            image 
          }         
        }
        section3 {
          tag
          title
          description
          image
          items {
            title
            text
            image 
          }         
        }                          
        feature1 {
          tag
          title
          description
          image       
        }          
        feature3 {
          tag
          title
          description
          image       
        }               
      }
    }      
  }
`;
